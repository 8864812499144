<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-form @submit.prevent>
      <validation-observer ref="observer">
        <b-container fluid class="mt--7">
          <b-row>
            <b-col>
              <b-overlay rounded="md">
                <b-card no-body>
                  <b-card-header class="border-1">
                    <h3 class="mb-0 d-inline">{{ pageName }}</h3>
                  </b-card-header>
                  <b-card-body>
                    <b-row>
                      <b-col md="6">
                        <base-select
                          label="給与年"
                          :rules="{required: true}"
                          :options="years"
                          v-model="year"
                        />
                      </b-col>
                      <b-col md="6">
                        <base-select
                          label="給与月"
                          :options="months"
                          v-model="month"
                          helpText="源泉票のみアップロードする場合は不要です。"
                        />
                      </b-col>
                      <b-col md="6">
                        <base-file
                          label="給与明細"
                          :multiple="true"
                          accept=".pdf"
                          @input="updatePaymentSlip"
                          helpText="ファイル名の先頭文字は社員番号にしてください。"
                        />
                      </b-col>
                      <b-col md="6">
                        <base-file
                          label="源泉票"
                          :multiple="true"
                          accept=".pdf"
                          @input="updateWithholding"
                          helpText="ファイル名の先頭文字は社員番号にしてください。"
                        />
                      </b-col>
                    </b-row>
                    <b-tabs v-if="paymentSlipFiles.length > 0 || withholdingFiels.length > 0" content-class="mt-3" card pills>
                      <b-tab v-if="paymentSlipFiles.length > 0" :title="`給与明細(${totalRows1})`" active>
                        <b-table
                          :items="paymentSlipFiles"
                          :fields="fields"
                          :current-page="currentPage1"
                          :per-page="perPage"
                          stacked="md"
                          show-empty
                          small
                          hover
                        >
                        </b-table>
                        <b-pagination
                          v-model="currentPage1"
                          :total-rows="totalRows1"
                          :per-page="perPage"
                          size="md"
                          class="mt-3"
                        ></b-pagination>
                      </b-tab>
                      <b-tab v-if="withholdingFiels.length > 0" :title="`源泉票(${totalRows2})`">
                        <b-table
                          :items="withholdingFiels"
                          :fields="fields"
                          :current-page="currentPage2"
                          :per-page="perPage"
                          stacked="md"
                          show-empty
                          small
                          hover
                        >
                        </b-table>
                        <b-pagination
                          v-model="currentPage2"
                          :total-rows="totalRows2"
                          :per-page="perPage"
                          size="md"
                          class="mt-3"
                        ></b-pagination>
                      </b-tab>
                    </b-tabs>
                  </b-card-body>
                  <b-card-footer>
                    <b-row>
                      <b-col cols="12" class="d-flex justify-content-between">
                        <span>
                          <b-button variant="primary" @click="uploadFiles">アップロード</b-button>
                        </span>
                        <span>
                        </span>
                      </b-col>
                    </b-row>
                  </b-card-footer>
                </b-card>
              </b-overlay>
            </b-col>
          </b-row>
        </b-container>
      </validation-observer>
    </b-form>
  </div>
</template>
<script>
import { Toast } from '../../components';
import { ajax, choice, common, config, constant } from '../../utils';
import { helper } from '../common';

export default {
  data() {
    return {
      paymentSlipFiles: [],
      withholdingFiels: [],
      fields: [
        { key: 'name', label: 'ファイル名' },
      ],
      year: common.getPrevMonthDict(new Date()).year,
      month: common.getPrevMonthDict(new Date()).month,
      currentPage1: 1,
      currentPage2: 1,
      perPage: config.rowsPerPage,
    }
  },
  computed: {
    pageName: function() {
      return common.getPageName(this.$route.name);
    },
    totalRows1: function() {
      return this.paymentSlipFiles.length;
    },
    totalRows2: function() {
      return this.withholdingFiels.length;
    },
    years: function() {
      return choice.years;
    },
    months: function() {
      return choice.months;
    },
  },
  created: function () {
    document.title = this.pageName;
  },
  methods: {
    updatePaymentSlip(fileArray) {
      this.paymentSlipFiles = fileArray;
    },
    updateWithholding(fileArray) {
      this.withholdingFiels = fileArray;
    },
    async uploadFiles() {
      if (this.paymentSlipFiles.length === 0 && this.withholdingFiels.length === 0) {
        alert(constant.ERROR.REQUIRE_FILES);
        return;
      } 
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        if (confirm(constant.CONFIRM.UPLOAD_FILE)) {
          common.loading();
          const vm = this;
          ajax.fetchPost(config.api.expense.upload, {
            year: vm.year,
            month: vm.month,
            payment_slip: vm.paymentSlipFiles,
            withholding: vm.withholdingFiels,
          }).then(data => {
            if (data.history && data.history.id) {
              Toast.success(constant.SUCCESS.UPLOADED);
              vm.$router.push({ name: 'expense-upload-detail', params: { id: data.history.id } });
            }
          }).catch(errors => {
            helper.show_errors(errors);
          }).finally(() => {
            common.loaded();
          });
        }
      }
    }
  },
}
</script>